<template>
  <div>
    <!-- แถบแรก -->
    <b-card no-body>
      <b-card-header>
        <h4 class="card-title">
          สรุปยอดเอเย่นต์
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-card-body>
          <!-- Table Function -->
          <b-row>
            <b-col cols="12 col-md-3">
              <div class="mb-1">
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_start_report"> วันที่ (จาก) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_start_report" id="date_start_report" class="form-control text-center" />

                </b-input-group>
              </div>

              <div class="mb-1">
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_end_report"> เวลา (จาก) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_end_report" id="date_end_report" class="form-control text-center" />

                </b-input-group>
              </div>
            </b-col>
            <b-col cols="12 col-md-3">
              <div class="mb-1">
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_end_report"> วันที่ (ถึง) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_end_report" id="date_end_report" class="form-control text-center" />

                </b-input-group>
              </div>

              <div class="mb-1">
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_end_report"> เวลา (ถึง) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_end_report" id="date_end_report" class="form-control text-center" />

                </b-input-group>
              </div>
            </b-col>
            <b-col cols="12 col-md-6">
              <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-primary waves-effect waves-light mr-1 mb-1"> ค้นหา </b-button>
              <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-success waves-effect waves-light mr-1 mb-1"> วันนี้ </b-button>
              <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-info waves-effect waves-light mr-1 mb-1"> เมื่อวาน </b-button>
              <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-secondary waves-effect waves-light mr-1 mb-1"> สัปดาห์นี้ </b-button>
              <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-danger waves-effect waves-light mr-1 mb-1"> เดือนนี้ </b-button>
              <b-button @click="this.filter=''" variant="btn-primary" class="btn bg-gradient-warning waves-effect waves-light mr-1 mb-1"> ออกรานงาน Excel </b-button>
            </b-col>
          </b-row>
          <!-- End Table Function -->
          <!-- Table Header -->
          <b-row>
            <b-col md="3" sm="2" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">แสดง</label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" v-on:change="onPageSelect" style="width: 60px"/>
                <label class="d-inline-block text-sm-right ml-50">รายการ</label>
              </b-form-group>
            </b-col>
            <b-col md="5" sm="6" class="my-1" />
            <b-col md="4" sm="4" class="my-1">
              <b-form-group label="ค้นหา :" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0" >
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" v-on:change="onPageSelect" type="search" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> ล้าง </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- End Table Header -->
          <!-- Table Body -->
          <b-row>
            <b-col cols="12">
              <b-table hover responsive :fields="fields" :items="items" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" show-empty>
                <template #thead-top="">
                  <b-tr class="text-center text-nowrap">
                    <b-th>จำนวน agent ทั้งหมด</b-th>
                    <b-th>{{ items.length }} คน</b-th>
                    <b-th>ฝากรวม {{ reduce_item('deposit') }} ครั้ง</b-th>
                    <b-th>รวมทั้งหมด {{ reduce_item('deposit_sum') }} บาท</b-th>
                    <b-th>ถอน {{ reduce_item('withdraw') }} ครั้ง</b-th>
                    <b-th>รวมถอน {{ reduce_item('withdraw_sum') }} บาท</b-th>
                    <b-th :class="{'text-success': income_sum() > 0, 'text-danger': income_sum() < 0}">{{ toMoney(income_sum()) }}</b-th>
                  </b-tr>
                </template>
                <template #empty="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #emptyfiltered="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #cell(page_url)="data">
                  <router-link :to="data.value">{{ data.value.slice(0, 20) }}{{ data.value.length > 20 ? '...' : ''}}</router-link>
                </template>
                <template #cell(id)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(deposit_sum)="data">
                  {{ toMoney(data.value) }}
                </template>
                <template #cell(withdraw_sum)="data">
                  {{ toMoney(data.value) }}
                </template>
                <template #cell(income)="data">
                  <span :class="{'text-success': data.value > 0, 'text-danger': data.value < 0}"> {{ toMoney(data.value) }} </span>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <!-- End Table Body -->
          <!-- Table Footer -->
          <b-row>
            <b-col sm="12" md="5">
              <div role="status" aria-live="polite">แสดง {{ func.show_page(perPage, currentPage) }} ถึง {{ func.show_page_to(perPage, currentPage, recordsTotal) }} จาก {{func.toNum(totalRows)}} รายการ (ค้นหา จากทั้งหมด {{perPage}} รายการ)</div>
            </b-col>
            <b-col sm="12" md="7">
              <b-pagination
                v-on:change="onPageChange"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="end"
                size="sm"
                class="my-0"
                :first-number="true"
                :last-number="true"
              >
                <template #prev-text>‹ ย้อนกลับ</template>
                <template #next-text>ถัดไป ›</template>
              </b-pagination>
            </b-col>
          </b-row>
          <!-- End Table Footer -->
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BInputGroup, BInputGroupPrepend, BFormInput, BButton, BFormGroup, BInputGroupAppend, BFormSelect,
  BPagination, BDropdown, BDropdownItem, BDropdownDivider,
  BTr, BTh,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import func from '@core/comp-functions/misc/func'

export default {
  components: {
    BCard, BCardHeader, BCardBody,
    BCol, BRow, BTable,
    BInputGroup, BInputGroupPrepend, BFormInput, BFormGroup, BInputGroupAppend,
    BButton,
    BFormSelect,
    BPagination,
    BDropdown, BDropdownItem, BDropdownDivider,
    BTr, BTh,
    flatPickr,
  },
  data() {
    return {
      func: func,
      BASE_URL: process.env.BASE_URL,
      date_start_report: '',
      date_end_report: '',
      draw: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      totalRows: 50,
      currentPage: 5,
      recordsTotal: 0,
      filter: '',
      filterOn: [],
      fields: [
        { key: 'id', label: 'ลำดับ', class: 'text-center' },
        { key: 'username', label: 'ชื่อเอเย่นต์', class: 'text-center text-nowrap' },
        { key: 'deposit', label: 'จำนวนครั้งที่ฝาก', class: 'text-center text-nowrap' },
        { key: 'deposit_sum', label: 'ฝากทั้งหมด', class: 'text-center text-nowrap' },
        { key: 'withdraw', label: 'จำนวนครั้งที่ถอน', class: 'text-center text-nowrap' },
        { key: 'withdraw_sum', label: 'ถอนทั้งหมด', class: 'text-center text-nowrap' },
        { key: 'income', label: 'กำไรขาดทุน', class: 'text-center text-nowrap' },
      ],
      items: [
        { id: 1, username: 'ADMIN', deposit: 0, deposit_sum: 0, withdraw: 0, withdraw_sum: 0, income: 0 },
      ],
      t_fetch: 0,
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
    }
  },
  mounted() {
    this.totalRows = this.items.length
    // this.fetch()
    // this.t_fetch = setInterval(() => {
      // this.fetch()
    // }, 1000 * 60)
  },
  watch: {
    filter: {
      handler() {
        this.fetch()
        this.totalRows = this.items.length
        this.currentPage = 1
      },
    }
  },
  methods: {
    reduce_item(key) {
      return this.items.map(a => a[key]).reduce((a, b) => a + b, 0);
    },
    income_sum() {
      return Number(this.reduce_item('deposit_sum') - this.reduce_item('withdraw_sum'));
    },
    toMoney(value) {
      const parts = parseFloat(value).toFixed(2).split('.')
      return parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? `.${parts[1]}` : '')
    },
    onFiltered(filteredItems) {
      // this.totalRows = filteredItems.length
      // this.currentPage = 1
    },
    onPageSelect() {
      let start = this.perPage * (this.currentPage - 1)
      this.fetch(start)
    },
    onPageChange(page) {
      let start = this.perPage * (page - 1)
      this.fetch(start)
    },
    load(data) {
      if (data == null || data.data == null) return;
      this.draw = data.draw
      this.items = data.data
      this.totalRows = data.recordsFiltered
      this.recordsTotal = data.recordsTotal
    },
    fetch(start=0) {
      let url = `log/log_page_list_page?draw=${this.draw}&start=${start}&length=${this.perPage}&search%5Bvalue%5D=${this.filter}&date_start=${this.date_start_report}&date_end=${this.date_end_report}&status=&_=${Date.now()}`;
      this.$http.get(url).then(res => { this.load(res.data) })
    },
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.t_fetch)
    next()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
